/* You can add global styles to this file, and also import other style files */

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import '../src/themes/geaui/variables.scss';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
// @include mat.all-legacy-component-typographies();
@include mat.all-component-typographies();
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$theme-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$theme-accent: mat.m2-define-palette(mat.$m2-blue-palette, A200, A100, A400);
// $theme-accent: mat.define-palette(mat.$gea-notification-color);

// The warn palette is optional (defaults to red).
$theme-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.m2-define-light-theme((color: (primary: $theme-primary, accent: $theme-accent, warn: $theme-warn, )));
// @include mat.typography-hierarchy($theme);
@include mat.all-component-themes($theme);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-legacy-component-themes($theme);

body {
    margin: 0;
    overflow-x: hidden;
}

.mat-mdc-snack-bar-container {

    &.notify-error {
        --mdc-snackbar-container-color: rgb(219, 30, 40);
        margin-top: 70px;
        transform: scale(1.25) !important;

        .mat-mdc-button .mdc-button__label {
            color: #ffffff !important;
            font-weight: bold;
        }
    }

    &.notify-success {

        --mdc-snackbar-container-color: rgb(18, 156, 77);
        margin-top: 70px;
        transform: scale(1.25) !important;

        .mat-mdc-button .mdc-button__label {
            color: #ffffff !important;
            font-weight: bold;
        }
    }
}

.comment,
.attachment {
    .mat-badge-accent {
        --mat-badge-background-color: rgba(63, 81, 181, 255);
        --mat-badge-text-color: white;
    }
}

.attach-title {
    .mat-badge-accent {
        --mat-badge-background-color: rgba(63, 81, 181, 255);
        --mat-badge-text-color: white;
    }
}


.geaui-button-primary {
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0;
    color: #ffffff;
    background: var(--geaui-vibrant-blue);
    border: 1px solid var(--geaui-vibrant-blue);
    padding: 8px 22px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    border-radius: 2rem !important;

    &.mat-mdc-button-disabled {
        background-color: lightgray;
        color: white;

        &:hover {
            pointer-events: none;
        }
    }

    &:hover {
        background: #4F4FCD;
        color: #FFFFFF;
        border-color: #4F4FCD;
        // background: var(--geaui-pale-blue);
        // color: var(--geaui-vibrant-blue);
        // border-color: var(--geaui-pale-blue);
    }
}

.geaui-button-secondary {
    color: var(--geaui-vibrant-blue) !important;
    background: var(--geaui-white) !important;
    border: 2px solid var(--geaui-vibrant-blue) !important;
    height: 38px !important;
    padding: 5px 17px !important;
    border-radius: 2rem !important;
    line-height: 20px !important;
    font-weight: 600 !important;
    cursor: pointer;
    border-radius: 2rem !important;

    &:hover {
        background: #4F4FCD;
        color: #FFFFFF;
        border-color: #4F4FCD;
    }
}

.geaui-chip {
    font-size: 15px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0;
    color: var(--geaui-vibrant-blue) !important;
    /* height: 50px; */
    padding: 11px 16px;
    gap: 4px;
    background: var(--geaui-white) !important;
    border: 2px solid var(--geaui-vibrant-blue) !important;

    button.mat-mdc-chip-remove {
        margin-left: 0 !important;
    }

    .mat-mdc-chip-action {
        padding-left: 0 !important;
    }

    .chip-content {
        display: flex;

        .filter-val {
            color: var(--geaui-vibrant-blue) !important;
            font-weight: 600;
        }
    }

    mat-icon {
        color: var(--geaui-vibrant-blue);
        font-weight: 600;
    }


    &:hover {
        background: #E6E6F8 !important;
        border-color: var(--geaui-pale-blue) !important;
        color: var(--geaui-vibrant-blue) !important;
    }
}

.geaui-inverted-chip {
    font-size: 15px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0;
    cursor: pointer;
    color: var(--geaui-white) !important;
    /* height: 50px; */
    padding: 11px 16px;
    gap: 4px;
    background: var(--geaui-vibrant-blue) !important;
    border: 2px solid var(--geaui-vibrant-blue) !important;

    button.mat-mdc-chip-remove {
        margin-left: 0 !important;

        mat-icon {
            margin: 0;
            color: white;
            fill: white;
        }
    }

    .mat-mdc-chip-action-label {
        color: var(--geaui-white) !important;
    }

    &:hover {
        background: #4F4FCD !important;
        color: #FFFFFF !important;
        border-color: #4F4FCD !important;
    }
}

.geaui-page-layout-content {
    background-color: var(--geaui-white);
}

// sidebar divider 
.sidebar {

    // overwriting icon css
    .devision-icon-container {
        .devision-icon {
            width: 40px !important;
            height: 40px !important;

            path {
                stroke: none !important;
            }
        }
    }

    ul.p-tree-container {
        .p-element {
            .p-treenode-label {
                .nav-item-name {
                    .node-label {
                        margin-left: 15px !important;
                    }
                }
            }

            // [aria-label="Dakota City Site"] {
            //     border-top: 1px solid #dee2e6;
            // }
        }
    }
}

.mat-mdc-option.mat-mdc-active {
    color: #0303B8 !important;
    background-color: rgba(3, 3, 184, 0.08) !important;
}


.hrt-title-container {

    @import '../src/themes/common.scss';

    .initial {
        display: inline-flex;
        align-items: center;

        img {
            cursor: pointer;
        }

        .title {
            padding: 10px 10px 6px 10px;
            margin: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            font-weight: bold;

            &.no-border {
                border-bottom: none;
            }
        }

        &.has-border {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }
    }

    .mat-mdc-tab-header {
        // margin-left: 20px;
        position: absolute;
        top: 30px;
        // width: 100%;
        right: 30px;

        .mat-mdc-tab {
            flex-grow: 0 !important;
        }
    }

    .mat-mdc-tab.mdc-tab {
        flex-grow: 0 !important;
        min-width: 10vw;
    }

    .mat-mdc-tab-label-container {
        border-bottom: 1px solid lightgray;
    }

    .mat-mdc-tab-body-wrapper {
        margin-top: 20px;
        // width: calc(100vw - 7rem - 200px);
    }

    &.has-border {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

}

// .mat-mdc-tab-header {
//     // margin-left: 20px;
//     position: absolute;
//     top: 30px;
//     // width: 100%;
//     right: 30px;
//     .mat-mdc-tab {
//         flex-grow: 0 !important;
//     }
// }



.mat-mdc-tab.mdc-tab {
    flex-grow: 0 !important;
    min-width: 250px;
}

.mat-mdc-tab-label-container {
    border-bottom: 1px solid lightgray !important;
}

.mat-mdc-tab-body-wrapper {
    margin-top: 20px;
    // width: calc(100vw - 7rem - 200px);
}

// .search-header {
// }

.mat-mdc-text-field-wrapper {
    background-color: transparent !important;

}

.mat-mdc-text-field-wrapper:hover .mat-mdc-form-field-focus-overlay {
    opacity: 0;
    background-color: transparent;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-focus-overlay {
    opacity: 0;
    background-color: transparent
}

// applying font to Material icons.
.hrt-table-container,
.comments-container {
    @import '../src/themes/common.scss';
}

.btn-brdr-radius {
    button {
        border-radius: 20px !important;
    }
}


.geaui-hrt-icon {
    svg {
        path {
            stroke: var(--geaui-night-blue);
        }
    }
}

.mat-mdc-datepicker-toggle.filter-datepicker {
    .mdc-icon-button {
        scale: 1.5;
    }
}

// mat-toggle 

.unit-toggle {

    .mat-mdc-slide-toggle {
        // background-color: lightblue !important;

        .mdc-switch__handle::after {
            background-color: var(--geaui-night-blue) !important;
        }
    }
}

.attachments-container {
    .attachment-list {
        .upload-msg {
            .icon-v2 {
                color: var(--geaui-vibrant-blue);
                scale: 1.5;
            }
        }
    }
}

.custom-mat-select {
    .autocomplete-inp {
        padding: 10px;
        border: none;
        width: 100%;
    }

    .mat-mdc-option.fetching-options {
        .mat-option-text {
            display: flex;
            align-items: center;
        }
    }

    .mat-mdc-optgroup {
        .mat-mdc-optgroup-label {
            color: var(--geaui-vibrant-blue);
            font-weight: bold;
        }
    }
}

.feedback-container, .filter-search-container {

    // form elements padding
    .mdc-text-field--outlined .mat-mdc-form-field-infix,
    .mdc-text-field--no-label .mat-mdc-form-field-infix {
        padding-top: var(--mat-form-field-filled-with-label-container-padding-top) !important;
        padding-bottom: var(--mat-form-field-filled-with-label-container-padding-bottom) !important;
    }
}