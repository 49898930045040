@use 'sass:map';
@import 'color';

// breakpoints
$gea-desktop-xl: 3840px;
$gea-desktop-lg: 2560px;
$gea-desktop-md: 1920px;
$gea-desktop-sm: 1440px;
$gea-tablet-landscape: 1024px;
$gea-tablet-portrait: 768px;
$gea-phone-landscape: 480px;
$gea-phone-portrait: 360px;
$gea-phone-landscape-min-height: 360px;

// breakpoint aliases
$gea-screen-sm: $gea-tablet-portrait;
$gea-screen-md: $gea-tablet-landscape;
$gea-screen-lg: $gea-desktop-lg;
$gea-screen-xl: $gea-desktop-xl;

// paddings around grid-layout
$gea-grid-margin-phone: 20px;
$gea-grid-margin-tablet: 40px;
$gea-grid-margin-desktop: 80px;

// space from the top of the tab menu
// to the bottom of the header (above)
$v-space-above-tab-menu: 32px;
// space from the bottom of the tab menu
// to the content below it
$v-space-below-tab-menu: 48px;

// space from the top of the main heading
// to the bottom of the header (above)
$v-space-above-main-heading: 48px;
// space from the bottom of the heading
// to the content below it
$v-space-below-main-heading: 32px;

// space from the top of the sub-heading
// to the bottom of th content above it
$v-space-above-sub-heading: 64px;
// space from the bottom of the sub-heading
// to the content below it
$v-space-below-sub-heading: 32px;

// space from the top of the action buttons
// to the bottom of the header (above)
$v-space-above-action-buttons: 36px;
// space from the bottom of the action buttons
// to the content below it
$v-space-below-action-buttons: 32px;

// vertical space between the bottom-border of a form field
// to the top border of the form-field below it
$v-space-between-form-fields: 48px;

// inner padding on card overlays to it's content (for phones)
$card-inner-padding-phone: 20px;
// inner padding on card overlays to it's content (for all other sizes)
$card-inner-padding: 40px;

// sidenav properties.
$sidenav-width: 300px;
$sidenav-collapsed-width: 20px;

*:not(mat-icon) {
    font-family: 'GEA Inter Regular', serif;
    --geaui-white: #{$white};
    --geaui-pure-white: #FFFFFF;
    --geaui-black: #{$black};
    --geaui-black-200: #C8C8C8;

    --geaui-active-blue-transparent: rgba(31, 157, 255, 0.1);
    --geaui-active-blue: rgb(31, 157, 255);
    --geaui-night-blue: rgb(0, 15, 65);
    --geaui-vibrant-blue: rgb(3, 3, 184);

    --geaui-bright-red: rgb(252, 118, 124);
    --geaui-brighter-red: rgba(235, 87, 87, 1);
    --geaui-red: rgb(219, 30, 40);
    --geaui-dark-red: rgb(87, 2, 29);
    --geaui-light-red: rgb(255, 164, 169);
    --geaui-tertiary-red-60: rgb(252, 75, 83);

    --geaui-bright-green: rgb(24, 221, 110);
    --geaui-green: rgb(119, 250, 142);
    --geaui-light-green: rgb(53, 189, 94);
    --geaui-light-green-2: rgb(83, 249, 155);
    --geaui-dark-green: rgb(10, 61, 27);

    --geaui-bright-yellow: rgb(255, 228, 91);
    --geaui-brighter-yelow: rgb(255, 221, 45);
    --geaui-yellow: rgb(242, 203, 0);
    --geaui-dark-yellow: rgb(95, 84, 7);
    --geaui-light-yellow: rgb(255, 234, 127);

    --geaui-blue-gray: rgba(230, 230, 248, 1);
    --geaui-bright-gray: rgba(240, 240, 240, 1);
    --geaui-light-gray: rgb(230, 230, 230);
    --geaui-gray: rgb(200, 200, 200);
    --geaui-dark-gray: rgb(100, 100, 100);
    --geaui-label-gray: rgb(140, 140, 140);
    --geaui-deep-gray: rgb(40, 40, 40);

    --geaui-active-green: rgb(26, 255, 128);
    --geaui-energetic-pink: rgb(255, 128, 255);

    // DairyNetCloud
    --ft-icon-dark-cyan: #006663;
    --ft-icon-light-cyan: #66a3a1;

    // overrides to allow for vertical gutter sizes (material doesn't distinguish)
    --mdc-layout-grid-gutter-desktop_xl: 10px 20px;
    --mdc-layout-grid-gutter-desktop_lg: 10px 20px;
    --mdc-layout-grid-gutter-desktop_md: 10px 20px;
    --mdc-layout-grid-gutter-desktop_sm: 10px 20px;
    --mdc-layout-grid-gutter-tablet_l: 10px 20px;
    --mdc-layout-grid-gutter-tablet_p: 10px 20px;
    --mdc-layout-grid-gutter-phone_l: 10px 20px;
    --mdc-layout-grid-gutter-phone_p: 10px 10px;
}

$font-path: '../../assets/fonts/';

@font-face {
    font-family: 'GEA Sans';
    src: url($font-path + 'GEASans/GEASans-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'GEA Sans';
    src: url($font-path + 'GEASans/GEASans-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'GEA Inter Regular';
    src: url($font-path + 'Inter/Inter-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'GEA Inter Regular';
    src: url($font-path + 'Inter/Inter-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'GEA Inter Regular';
    src: url($font-path + 'Inter/Inter-Italic.ttf') format('truetype');
    font-style: italic;
}

@font-face {
    font-family: 'GEA Inter Medium';
    src: url($font-path + 'Inter/Inter-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'GEA Inter MediumItalic';
    src: url($font-path + 'Inter/Inter-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'GEA Inter SemiBold';
    src: url($font-path + 'Inter/Inter-SemiBold.ttf') format('truetype');
}