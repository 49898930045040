table {
  width: 100%;
}

.mat-mdc-row:hover {
  background-color: #F5F5F5;
}

// .TableHeader {
//   background-color: white;
//   padding-top: 10px;
// }

.TableNewButton {
  margin-right: 10px;
  margin-left: 5px;
}

// .TableHeaderIcon {
//   margin-top: 10px;
//   margin-left: 20px;
// }

// .TableHeaderText {
//   font-weight: bold;
//   font-size: 24px; 
//   margin: 0px 20px 0px 20px;
//   width: 1%;
//   white-space: pre;
// }

.TableHeaderSearch {
  width: 100%;
  // position:fixed;
  padding: 10px;
}

.mat-mdc-header-cell {
  font-weight: bold;
  color: black;
  font-size: small;
}

.mat-mdc-row:nth-child(even) {
  background: #F5F5F5;
}

::ng-deep .mat-mdc-paginator .mat-mdc-paginator-icon {
  border-radius: 70% !important;
  background-color: var(--geaui-vibrant-blue) !important;
  color: white;
  fill: white;
}

.mat-mdc-row:hover {
  background-color: var(--geaui-blue-gray);
}


.TableHeaderSearchBox {
  margin-left: 100px;
  margin-top: -10px;
  margin-bottom: -10px;
  width: 90%;
}

.TableIconColumn {
  width: 75px;
}

.TableLargeIconColumn {
  width: 100px;
}

.TableLabelColumn {
  width: 25%;
}

.TableActionsColumn {
  width: 50px;
}

.TableRowClickable {
  cursor: pointer;
}

.MarginBottomSmall {
  margin-bottom: 15px;
}

.MarginBottom {
  margin-bottom: 30px;
}

.MarginTopSmall {
  margin-top: 15px;
}

.MarginTop {
  margin-top: 30px;
}

.MarginLeft {
  margin-left: 30px;
}

.MarginAll {
  margin: 30px;
}

.ActionIcon {
  cursor: pointer;
}

.GeaButton {
  background-color: rgb(26, 255, 128);
  border-radius: 24px;
}

// mat-styles overriding

.material-icons {
  font-family: 'Material Icons';
}

.material-icons:not(.custom-svg) {
  color: var(--geaui-night-blue);
}

.mat-mdc-tab-group.mat-mdc-primary .mat-mdc-ink-bar,
.mat-mdc-tab-nav-bar.mat-mdc-primary .mat-mdc-ink-bar {
  background-color: var(--geaui-vibrant-blue);
}

.mdc-tab-indicator--active {
  opacity: 1 !important;

  .mdc-tab__text-label {
    color: var(--geaui-vibrant-blue);
    font-weight: 600;
  }
}

// Mat-tab styles.
.mat-mdc-tab {
  .mdc-tab--active {
    .mdc-tab__text-label {
      color: var(--geaui-vibrant-blue) !important;
      font-weight: 600;
    }

    // &:hover {
      //   }
    }
    .mdc-tab-indicator {
      opacity: 1;
      .mdc-tab-indicator__content--underline {
        border-bottom: 2px solid var(--geaui-vibrant-blue) !important;
      }
  }
}

.hrt-export-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgray;
  width: 80px;
  height: 40px;
  cursor: pointer;
  background-color: white;
  margin: 0 2px 5px auto;

  .csv-text {
    font-weight: 600;
    color: var(--geaui-night-blue);
  }

  &:hover {
    background-color: #e0e0e0;
  }
}